import React from 'react';

import Client from '../../apis/Client';
// import { DEFAULT_SELECTS } from './utils';

import { required, latitude, longitude } from '../../validation/form';

import CustomInput from '../../components/forms/CustomInput';
import CustomSelect from '../../components/forms/CustomSelect';
import CustomSlider from '../../components/forms/CustomSlider';
import FormModal from '../../components/FormModal';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

const newEntry = {
  name: 'New',
  label: 'New',
  value: '__null__'
};

const validate = (values) => {
  const errors = {};
 
  required(errors, values, 'name');

  latitude(errors, values, 'latitude');
  longitude(errors, values, 'longitude');

  // noSpaces(errors, values, 'deviceId');

  return errors;
};

const DeviceModal = ({
  showModal,
  setShowModal = () => {},
  device,
  companyId,
  locationId,
  onSuccess = () => {},
}) => {
  const { data: monitoringAreaRsp } = useQuery({
    queryKey: ['monitoring-areas', device],
    queryFn: () =>
      Client.get(
        `/api/v1/admin/locations/${locationId}/monitoring-areas`
      ),
    useErrorBoundary: (error) => error.status === 401,
    enabled: !!showModal
  });

  const {
    mutateAsync: submitRequest,
    error,
  } = useMutation({
    mutationKey: 'create-edit-device',
    mutationFn: (data) => {
      const body = { ...data };
      if (body.monitoringAreaId === newEntry.value) {
        delete body.monitoringAreaId;
      }

      if (device) {
        let url = `/api/v1/admin/devices/${device.id}`;
        return Client.put(url, body);
      } else {
        let url = `/api/v1/admin/locations/${locationId}/devices`;
        return Client.post(url, body);
      }
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess();
    }
  });

  const monitoringAreas = monitoringAreaRsp || [];

  const initialValues = {
    deviceId: device?.id || '',
    name: device?.name || '',
    deviceIP: device?.device_ip || '',
    rtspPort: device?.rtsp_port || '',
    username: device?.user_name || '',
    password: '',
    isRtsp: device?.is_rtsp || false,
    latitude: device?.latitude || '',
    longitude: device?.longitude || '',
    monitoringAreaId: device?.monitoring_area?.id || '__null__',
    locationId,
    monitoringAreaName: ''
  };

  const headerLabel = device ? `Update Device ${device?.name}` : 'Add Device';

  const areaOptions = (monitoringAreas || []).map((area) => {
    return {
      name: area.name,
      label: area.name,
      value: area.id
    };
  });

  if (!showModal) {
    return null;
  }

  const monitoringAreaName = formik => formik.values.monitoringAreaId === newEntry.value
    ? (
      <CustomInput
        label="New Monitoring Area Name"
        name="monitoringAreaName"
        placeholder="Autogenerate"
        formik={formik}
      />
    )
    : null;

  return (
    <FormModal
      setShowModal={setShowModal}
      showModal={showModal}
      title={headerLabel}
      submitBtnLabel={device ? "Update" : "Create"}
      initialValues={initialValues}
      onSubmit={submitRequest}
      validate={validate}
      error={error}
    >
      {(formik) => (
        <>
          <CustomInput
            label="ID"
            name="deviceId"
            formik={formik}
            required={!!device}
            placeholder={device ? '' : 'autogenerated uuid'}
          />

          <CustomInput
            label="Name"
            name="name"
            formik={formik}
            required
          />

          <CustomInput
            label="Device IP"
            name="deviceIP"
            formik={formik}
            required
          />

          {/*<CustomInput
            label="RTSP Port"
            name="rtspPort"
            formik={formik}
          />*/}

          {/*<CustomInput
            label="Device Username"
            name="username"
            formik={formik}
          />*/}

          {/*<CustomInput
            label="Device Password"
            name="password"
            formik={formik}
            placeholder="••••••••••"
            password
          />*/}

          {/*<CustomSlider
            label="Is RTSP"
            name="isRtsp"
            formik={formik}
          />*/}

          <CustomInput
            label="Latitude"
            name="latitude"
            formik={formik}
            number
            required
          />

          <CustomInput
            label="Longitude"
            name="longitude"
            formik={formik}
            number
            required
          />

          <CustomSelect
            name="monitoringAreaId"
            label="Monitoring Area"
            options={[newEntry, ...areaOptions]}
            formik={formik}
          />

          {monitoringAreaName(formik)}
        </>
      )}
    </FormModal>
  );
};

export default DeviceModal;
