import React, { useRef } from 'react'

// images
import imgAnalytics from '../images/analytics.jpg'
import imgEcosystems from '../images/ecosystems.jpg'
import imgGrow from '../images/grow.jpg'
import imgHero from '../images/hero.jpg'
import imgInTheKnow from '../images/in-the-know.jpg'

import css from './styles.module.scss'

import HomeHero from './Components/HomeHero/HomeHero'
import HomeCard from './Components/HomeCard/HomeCard'
import IconAI from '../icons/AI'
import IconCamera from '../icons/Camera'
import IconSecurity from '../icons/Security'
import IconPrivacy from '../icons/Privacy'
import IconBilling from '../icons/Billing'
import IconHardware from '../icons/Hardware'
import IconConnectivity from '../icons/Connectivity'
import IconHealth from '../icons/Health'
import IconAnalytics from '../icons/Analytics'
import IconCompliance from '../icons/Compliance'
import IconCart from '../icons/Cart'
import IconProfit from '../icons/Profit'

const HomeContent = ({ title = '', content = '', classes = 'col-12' }) => {
  return (
    <div className={`d-flex flex-column position-relative ${classes} ${css.homeContent} ${css.prod}`} style={{ zIndex: 10 }}>
      {title && <h2 className={`mb-5`}>{title}</h2>}
      {content}
    </div>
  )
}

const Category = () => {
  const cats = [
    {
      icon: <IconHealth bgcolor='#3099FD' />,
      title: 'Health & Safety',
      para: `Applications focused on the well being and health of the animals`
    },
    {
      icon: <IconAnalytics bgcolor='#3099FD' />,
      title: 'Analytics',
      para: `Tracks information about the animals to derive deeper insights to drive higher ROI on each animal`
    },
    {
      icon: <IconCompliance bgcolor='#3099FD' />,
      title: 'Operations',
      para: `Applications focused on ensuring environmental, employee or lot compliance`
    },
  ]

  const catEls = cats.map(cat => {
    return (
      <div key={cat.title} className={`align-items-center d-flex flex-column justify-content-start ${css.card}`}>
        {cat.icon}
        <h3 className={`mb-2`}>{cat.title}</h3>
        <p className={`text-center`}>{cat.para}</p>
      </div>
    )
  })
  
  return (
    <div className={`row align-items-start d-flex flex-column flex-md-row justify-content-md-between g-0 ${css.holder}`}>
      {catEls}
    </div>
  )
}

const HomeProducers = () => {
  const formRef = useRef(null)

  const scriptUrl = "https://script.google.com/macros/s/AKfycbzE6y99VC95zwjvE131svEfCtpsMmk1ocLQ-_yEfYIQVrv1zCnhTXr6w1hNHM1z9bNk/exec"

  const handleSubmitForm = (e) =>{
    e.preventDefault()

    fetch(scriptUrl, {method: 'POST', body: new FormData(formRef.current)})
    .then(res => {
      alert("SUCCESSFULLY SUBMITTED")
    });
  }

  return (
    <section className={`container d-flex flex-column ${css.container}`}>

      <HomeHero
        h1={<>Finally - Technology that<br />Helps Producers</>}
        sub={`Herd Sense offers multiple products and technologies to producers to support their own unique recipe for success`}
        img={imgHero} />

      <HomeCard id='apps'>
        <div className={`col-sm-6 col-md-3 col-md-3 mb-5 mb-sm-0 px-3 text-center ${css.industry}`}>
          <IconCart
            title='App Marketplace'
            bgcolor='#3099FD' />
        </div>
        <div className={`col-sm-6 col-md-3 col-md-3 px-3 text-center ${css.ai}`}>
          <IconAI title='Leverage State of the art Technology' />
        </div>

        <HomeContent
          title='Increase Profit'
          content={
            <>
              <p>Herd Sense is built from the ground up to help producers increase profit.</p>
              <p>Herd Sense puts poles in feed lots with the ability to install and use a marketplace of applications that solve Producers real world problems.</p>
            </>
          }
          classes='col-12 col-md-6' />
      </HomeCard>

      <HomeCard id='mgmt'>
        <div className={`col-12 align-items-start d-flex flex-column flex-md-row ${css.iconFocus}`}>
          <div className={`col-12 col-md-9 mb-sm-5 mb-md-0 pe-md-3 ${css.manages}`}>
            <h4 className={`col-12 py-2 text-center bg-black text-white w-100`}>Herd Sense Manages</h4>
            
            <div className="align-items-end d-flex flex-row flex-wrap">
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconCamera title='Cameras' bgcolor='#3099FD' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconSecurity title='Security' bgcolor='#3099FD' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-md-0 text-center">
                <IconPrivacy title='Privacy' bgcolor='#3099FD' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">
                <IconBilling title='Billing' bgcolor='#3099FD' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">
                <IconHardware title='Hardware' bgcolor='#3099FD' />
              </div>      
              <div className="col-6 col-sm-4 col-md-2 mb-5 mb-sm-0 text-center">

                <IconConnectivity title='Connectivity' bgcolor='#3099FD' />
              </div>
            </div>
          </div>

          <div className={`col-12 col-md-3 ps-md-3 ${css.focus}`}>
            <h4 className={`col-12 py-2 text-center bg-black text-white`}>Producer Focus</h4>
            <div className="align-items-end d-flex flex-row flex-wrap">
              <div className="col-6 col-sm-4 col-md-12 mb-md-0 text-center">
                <IconProfit title='Profit' bgcolor='#3099FD' />
              </div>
            </div>
          </div>
        </div>

        <HomeContent
          content={
            <>
              <p className={css.contentP}>Herd Sense manages the camera, all connectivity concerns, security, privacy, marketplace hardware so producers can focus on the combination of innovative software solutions that maximize profitability.</p>
            </>
          }
        />
      </HomeCard>

      <HomeCard id='analytics' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgAnalytics})` }} />
          
        <div className={`col-md-6 offset-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Analytics + Actionability</h2>
                <p>Herd Sense processes 24/7 video of cattle to classify and uniquely identify each individual animal. This information is passed to marketplace applications to further process. These applications in turn can raise alerts or deliver insights to producers.</p>
                <p>With applications to alert to multiple health conditions and analyze cattle activity Herd Sense gives producers many avenues for formulating their profitability recipe.</p>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

      <HomeCard id={'inTheKnow'} classes='p-0 position-relative' minHeight='340' valign='start'>
        <div className={`col-12 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgInTheKnow})` }} />
        
        <div className={`col-12 position-absolute`} />
        
        <div className={`col-12`}>
          <HomeContent
            classes={`align-items-start d-flex flex-column flex-md-row text-white`}
            content={
              <>
                <div className="col-md-6 pe-md-3">
                  <h3 className={`mb-3`}>Be in the Know</h3>
                  <p className={css.sm}>Herd Sense is currently under development and in an invite-only stage of testing.</p>
                  <p className={css.sm}>Leave your email address and we'll be happy to schedule a meeting for a deeper discussion about the benefits of being included in the Herd Sense platform.</p>
                </div>
                
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1 ps-md-3 position-relative text-end">
                  <form id={css.formInTheKnow} method='post' ref={formRef} className={`align-items-start d-flex flex-column flex-sm-row flex-wrap`} onSubmit={handleSubmitForm}>
                    <div className={`col-12 align-items-start d-flex flex-column flex-sm-row py-0 px-0`}>
                      <div className={`col-12 col-sm-6 d-flex flex-column pe-sm-3 ${css.item}`}>
                        <label htmlFor="first_name" className="text-start w-100">First Name</label>
                        <input type="text" name="first_name" id="first_name" className="w-100" />
                      </div>
                      <div className={`col-12 col-sm-6 d-flex flex-column ps-sm-3 ${css.item}`}>
                        <label htmlFor="last_name" className="text-start w-100">Last Name</label>
                        <input type="text" name="last_name" id="last_name" className="w-100" />
                      </div>
                    </div>
                    <div className={`col-12 d-flex flex-column py-0 px-0 ${css.item}`}>
                      <label htmlFor="email" className="text-start w-100">Email</label>
                      <input type="email" name="email" id="email" className="w-100" />
                    </div>

                    <button type="submit" className={`btn ms-auto ${css.btn}`}>Submit</button>
                  </form>
                </div>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

      <HomeCard id='ecosystem' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Extensive App Ecosystem</h2>
                <p>Herd Sense applications can do a wide range of tasks and solve many different and interesting problems, bringing virtually unlimited value to beef producers, and a superior product to consumers.</p>
                <p>Operators can install apps via the marketplace a la carte, read and write reviews, manage hardware, herd, and installed apps.</p>
              </>
            }
          >
          </HomeContent>
        </div>
          
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgEcosystems})` }} />
      </HomeCard>

      <div id={css.categories} className={`d-flex flex-column`}>
        <div className={`align-items-center d-flex justify-content-center ${css.title}`}>
          <h3 className={`mb-0 text-center`}>Applications are Organized into Categories</h3>
        </div>

        <Category />
      </div>

      <HomeCard id='developGrow' classes='flex-sm-column position-relative' minHeight='400' valign='start'>
        <div className={`col-md-6 position-absolute bg ${css.noOverlay}`} style={{ backgroundImage: `url(${imgGrow})` }} />
          
        <div className={`col-md-6 offset-md-6 ${css.content}`}>
          <HomeContent
            content={
              <>
                <h2 className={`mb-4 ${css.h2}`}>Grow Together</h2>
                <p>It is easy to imagine producers using Herd Sense to use applications that provide alerts when cattle are not eating or drinking enough, spooked by something outside the pen, or even analyzing step counts.</p>
                <p>Producers pay for apps on a per-head observed basis, which allows for direct profit per application calculations.</p>
              </>
            }
          >
          </HomeContent>
        </div>
      </HomeCard>

    </section>
  )
}

export default HomeProducers