import React from 'react';

import css from './styles.module.scss';

function HeaderStat(props) {
  const { label, color, data, subdata } = props;

  return (
    <div className={css.stat}>
      <span className={css.num}>{typeof data === 'number' || data ? data : '0'}</span>
      <span className={css.sub}>{subdata}</span>
      <span
        className={css.label}
        style={{ color }}
      >{label}</span>
    </div>
  );
}

export default HeaderStat;
