import React, {useState} from 'react';
import { Outlet } from 'react-router-dom';

import Topbar from '../Admin/Topbar';

const AdminLayout = (props) => {
  const { hideTopbar } = props;

  const [navOpen, setNavOpen] = useState(false);

  const topbar = hideTopbar
    ? null
    : <Topbar navOpen={navOpen} setNavOpen={setNavOpen} />;

  return (
    <>
      {topbar}
      <section
        id="mainContent"
        className={`admin`}
      >
        <Outlet />
      </section>
    </>
  );
};

export default AdminLayout;
