import React, { useState } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';

import Client from '../../apis/Client';

import Metric from './Metric';
import LocationModal from '../components/LocationModal';
import Devices from './Devices';
import MonitoringAreas from './MonitoringAreas';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import css from '../styles.module.scss';

export default function Location({ id: idFromProp }) {
  const { locationId } = useParams();

  const { isLoading, isError, error, data, refetch } = useQuery({
    queryFn: () => Client.get(`/api/v1/admin/locations/${locationId}`),
    queryKey: ['location', locationId],
    useErrorBoundary: (error) => error.status === 401,
  });
  const { mutate: activateLocation } = useMutation({
    mutationKey: 'activate-location',
    mutationFn: ({ id, activate }) => {
      let url = `/api/v1/admin/locations/${locationId}/activate?activate=${activate}`;
      return Client.put(url);
    },
    onSuccess: (values) => {
      refetch();
    },
    useErrorBoundary: (error) => error.status === 401,
  });

  const navigate = useNavigate();

  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const onLocationChange = (event) => {
    navigate(`/admin/locations/${event.target.value}`);
  };

  if (isError) {
    return <h4 style={{ color: 'red' }}>{error.msg}</h4>;
  }

  if (isLoading) {
    return <Loader></Loader>;
  }

  const location = data;
  const siblingLocations = location?.company?.locations;

  const locationName = location
    ? `${location.company.name} -- ${location.address.city}, ${location.address.state}`
    : 'No Location Selected';

  const pocName = location
    ? `${location.poc_first_name} ${location.poc_last_name}`
    : '--';

  return (
    <>
      <div id="mainContent" className={`admin`}>
        <div id={css.glActions}>
          <button
            type="button"
            className='btn btn-outline-primary'
            onClick={() => navigate(`/admin/customers/${location?.company_id}`)}>
            Back
          </button>
        </div>

        <div id={css.overview} className='tableContain'>
          <div id={css.titleLocation}>
            <PageSubTitle
              title={locationName}
            />

            <div className={`selectBlock`}>
              <label>Location:</label>

              <select
                onChange={onLocationChange}
                name="customer"
                value={locationId}>
                {siblingLocations?.map((loc) => {
                  return (
                    <option key={loc.id} value={loc.id}>{`${
                      loc.is_primary
                        ? 'Primary: '
                        : ''
                    }${loc.address.city} , ${
                      loc.address.state
                    }`}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={css.contentBlock}>
            <div className={css.item}>
              <h3>Point of Contact</h3>
              <p>
                {pocName}
                <br />
                {location?.poc_email || '--'}
                <br />
                {location?.poc_phone || '--'}
              </p>
            </div>
            <div className={css.item}>
              <h3>Current Balance</h3>
              <p>$1,234</p>
            </div>
            <div className={`${css.item} ${css.actions}`}>
              <button
                type="button"
                className='btn btn-outline-primary'
                onClick={() => {
                  setShowEditLocationModal(true);
                }}>
                Edit Location
              </button>
              <button
                type="button"
                className='btn btn-danger'
                onClick={() => {
                  activateLocation({
                    id: location.id,
                    activate: !location?.is_active,
                  });
                }}>
                {location?.is_active
                  ? 'Deactive Location'
                  : 'Activate Location'}
              </button>
            </div>
          </div>
        </div>

        <Metric location={location} />

        <Devices location={location} />
        <MonitoringAreas location={location} />
          
        <LocationModal
          onSuccess={(rsp) => refetch()}
          location={location}
          companyId={location?.company.id}
          showModal={showEditLocationModal}
          setShowModal={setShowEditLocationModal}
        />
      </div>
    </>
  );
}
