import React, { useContext } from 'react';

import css from './styles.module.scss';
import IconEllipsis from '../../icons/Ellipsis';
import Client from '../../apis/Client';
import AuthContext from '../../context/AuthContext';

function Sidebar(props) {
  const { setLocation, locations, isLoading, selected } = props;

  const { logout, userData } = useContext(AuthContext);

  const sidebarEls = isLoading
    ? null
    : locations
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .map((l) => {
        const isSelected = selected === l.id;

        const locationStr = `${l.name || `${l.address.city}, ${l.address.state}`}`;

        return (
          <div key={l.id} onClick={() => setLocation(l.id)} className={`${css.pen} ${isSelected ? css.active : null}`}>
            <span>{locationStr}</span>
            <div className={css.length}>{l.count_check + l.count_urgent}</div>
          </div>
        )
      });

  return (
    <div className={css.sidebar}>
      <div className={css.title}>
        My Vet Checks
        <IconEllipsis />
      </div>

      {sidebarEls}
    </div>
  );
}

export default Sidebar;
