import React from 'react';
import { Formik, Form } from 'formik';
import css from '../styles.module.scss';

import Client from '../../apis/Client';

import useMutation from '../../hooks/useMutation';

import Loader from '../../components/Loader/Loader';
import CustomInput from '../../components/forms/CustomInput';
import FormModal from '../../components/FormModal';

import {required, phone, email, state} from '../../validation/form';

const validate = (values) => {
    const requiredFields = ['companyName', 'email', 'state', 'phone', 'city', 'firstName', 'lastName', 'postalCode', 'address1'];

    let errors = {};
    requiredFields.forEach((field) => {
        required(errors, values, field);
    });

    email(errors, values, 'email');
    state(errors, values, 'state');
    phone(errors, values, 'phone');

    return errors;
};

const CustomerModal = ({
    showModal,
    setShowModal = () => {},
    customer,
    onSuccess = () => {},
    isDeactivate = false,
}) => {
    const {
        mutateAsync: submitRequest,
        isLoading,
        isError,
        error,
    } = useMutation({
        mutationKey: 'create-edit-customer',
        mutationFn: (data) => {
            let url = '/api/v1/admin/customers';
            if (data.id) {
                url = url + '/' + data.id;
                return Client.put(url, data);
            } else {
                return Client.post(url, data);
            }
        },
        onSuccess: (values) => {
            setShowModal(false);
            onSuccess(values);
        },
    });

    const initialValues = {
        companyName: customer?.name || '',
        address1: customer?.address?.address1 || '',
        address2: customer?.address?.address2 || '',
        city: customer?.address?.city || '',
        state: customer?.address?.state || '',
        postalCode: customer?.address?.postal_code || '',
        firstName: customer?.poc_first_name || '',
        lastName: customer?.poc_last_name || '',
        phone: customer?.poc_phone || '',
        email: customer?.poc_email || '',
    };

    const onSubmit = async (values) => {
        const newValues = { ...values };
        if (customer) {
            newValues.id = customer.id;
        }
        submitRequest(newValues);
    };

    const headerLabel = customer ? `Edit ${customer.name}` : 'Add Customer';

    return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={headerLabel}
          submitBtnLabel={customer ? "Update" : "Create"}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                }}>
                    <CustomInput
                        label="Company Name"
                        name="companyName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Street Address"
                        name="address1"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Building Address"
                        name="address2"
                        formik={formik}
                    />

                    <CustomInput
                        label="City"
                        name="city"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="State"
                        name="state"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Postal Code"
                        name="postalCode"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact First Name"
                        name="firstName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact Last Name"
                        name="lastName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Contact Phone"
                        name="phone"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Email"
                        name="email"
                        formik={formik}
                        required
                    />
                </div>
            )}
        </FormModal>
    );
};

export default CustomerModal;
