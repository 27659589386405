import React, {useCallback} from 'react';
import {useMutation} from '@tanstack/react-query';

import TextInput, {
  useTextInput,
  required,
  email as emailRequired
} from '../../components/TextInput';

import Client from '../../apis/Client';

const validation = [required, emailRequired];

function ResetPasswordModal(props) {
  const {setShowForgot} = props;

  const {mutate: resetRequest, error} = useMutation({
    mutationKey: 'reset-password',
    mutationFn: () => Client.post('/api/v1/auth/forgot-password', { email: email.value }),
    onSuccess: () => {
      setShowForgot(false);
    }
  });

  let errorMsg = null;
  if (error) {
    errorMsg = 'Sorry, we cannot reset your password at this time. We are currently working to fix the issue.'
  }

  const email = useTextInput('', validation);

  return (
    <div className="modal fade show" id="exampleModalLive" tabIndex="-1" aria-labelledby="newDevModalLabel" style={{ display: 'block' }} aria-modal="true" role="dialog">
      
      <div className={`position-fixed backdrop`} onClick={() => setShowForgot(false)} />

      <form onSubmit={e => e.preventDefault()} className={`d-inline`}>
        <div className={`modal-dialog modal-dialog-centered`}>

          <div className={`modal-content`}>
            <div className={`modal-header position-relative bgNavy textWhite`}>
              <h5 className="modal-title" id="newDevModalLabel">Forgot Password</h5>
              <button type="button" className={`border-0 position-absolute close`} data-dismiss="modal" aria-label="Close" onClick={() => setShowForgot(false)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1341 2.58996L12 1.72791L10.2721 0L9.41004 0.862047L6 4.27209L2.58996 0.865862L1.72791 0L0 1.72791L0.862047 2.58996L4.27209 6L0.865861 9.41004L0 10.2721L1.72791 12L2.58996 11.138L6 7.72791L9.41004 11.138L10.2721 12L12 10.2721L11.138 9.41004L7.72791 6L11.138 2.58996H11.1341Z" fill="white"/>
                </svg>
              </button>
            </div>

            <div className="modal-body">
              <TextInput
                label="Email"
                name="email"
                data={email}
                // className={`col-12 item`}
                itemStyle={{ gap: 4 }}
                labelStyle={{ fontWeight: 600 }}
                required
              />

              <p style={{
                color: '#383838',
                fontSize: 12,
                fontWeight: 600,
                marginTop: 10
              }}>We will send a password reset link to your email if it exists in our system.</p>

              <p className="px-0" style={{color: 'var(--bs-danger)'}}>{errorMsg}</p>
            </div>

            <div className={`align-items-center d-flex justify-content-between modal-footer border-0 bgGray246`}>
              <button
                type="button"
                className={`btn btn-danger`}
                data-dismiss="modal"
                onClick={() => setShowForgot(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`btn btn-primary`}
                disabled={!email.valid}
                onClick={resetRequest}
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>
  )
}

export default ResetPasswordModal;