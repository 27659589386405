import {
  DEVELOPER_PATH,
  PRODUCER_PATH,
  REVIEW_PATH,
  VET_PATH,
  ADMIN_PATH,
  NOT_FOUND_PATH
} from './constants';

export function commaSeparate(number) {
  number = typeof number === 'string' ? parseFloat(number) : number;

  const rem = number % 1;

  const intStr = Math.floor(number).toString();

  let str = '';

  for (let i = intStr.length - 1; i >= 0; i -= 1) {
    const c = intStr.length - i - 1;
    if (c !== 0 && c % 3 === 0) {
      str = ',' + str;
    }
    str = intStr.charAt(i) + str;
  }

  if (rem) {
    return str + rem.toFixed(1).slice(1);
  }

  return str;
}

export function dollarPretty(number) {
  number = typeof number === 'string' ? parseFloat(number) : number;

  if (number < 1000) {
    return '$' + number.toFixed(2);
  }

  if (number < 1000000) {
    return '$' + commaSeparate(number / 1000) + 'K';
  }

  if (number < 1000000000) {
    return '$' + commaSeparate(number / 1000000) + 'M';
  }

  return '$' + commaSeparate(number / 1000000000) + 'B';
}

export const DEFAULT_SELECTS = [
  {
    value: 'undefined',
    display: 'Select...',
  },
  {
    value: 'null',
    display: 'None',
  },
];

export const trimLeft = function (charlist) {
  if (charlist === undefined) charlist = 's';

  return this.replace(new RegExp('^[' + charlist + ']+'), '');
};

export const trimRight = function (charlist) {
  if (charlist === undefined) charlist = 's';

  return this.replace(new RegExp('[' + charlist + ']+$'), '');
};

export const hasRoutePermissions = (roles, portalDefaultPath) => {
  if (!roles || !roles.length) {
    return false;
  }

  const portals = [
    { type: 'system', path: [ADMIN_PATH, PRODUCER_PATH, VET_PATH, DEVELOPER_PATH] },
    { type: 'reviewer', path: [REVIEW_PATH] },
    { type: 'producer', path: [PRODUCER_PATH] },
    { type: 'vet', path: [VET_PATH] },
    { type: 'developer', path: [DEVELOPER_PATH] },
  ];

  for (const portal of portals) {
    const role = roles.find((r) => r.type === portal.type);
    if (role && portal.path.includes(portalDefaultPath)) {
      return true;
    }
  }

  return false;
};

export const getDefaultPath = (roles) => {
  if (!roles || !roles.length) {
    return NOT_FOUND_PATH;
  }

  const portals = [
    { type: 'system', path: PRODUCER_PATH },
    { type: 'reviewer', path: REVIEW_PATH },
    { type: 'producer', path: PRODUCER_PATH },
    { type: 'vet', path: VET_PATH },
    { type: 'developer', path: DEVELOPER_PATH },
  ];

  for (const portal of portals) {
    const role = roles.find((r) => r.type === portal.type);
    if (role) {
      return portal.path;
    }
  }

  return NOT_FOUND_PATH;
};

export const collatorSort = (field, asc = true, options = {}) => {
  const optionObj = {
    numeric: true,
    sensitivity: 'base',
    ...options
  };

  const collator = new Intl.Collator('en', optionObj);

  // can send collator either a fieldname or a function to transform data for sorting
  return (item1, item2) => {
    let val1 = asc ? item1[field] : item2[field];
    let val2 = asc ? item2[field] : item1[field];
    if (typeof field === 'function') {
      val1 = asc ? field(item1) : field(item2);
      val2 = asc ? field(item2) : field(item1);
    }

    if (!val1) {
      return 1;
    }

    if (!val2) {
      return -1;
    }

    return collator.compare(val1, val2);
  };
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

export const getAbsDay = (date) => {
  const timestamp = date instanceof Date ? date : new Date(date);
  return Math.floor(timestamp.getTime() / (1000 * 60 * 60 * 24));
}
