import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Topbar from '../Producer/Topbar';

import SocketContext from '../context/SocketContext';

import SocketClient from '../SocketClient';

const ProducerLayout = (props) => {
    const { hideTopbar } = props;

    const { pathname } = useLocation();

    const [socketClient] = useState(new SocketClient());

    const [navOpen, setNavOpen] = useState(false);

    useEffect(() => {
        socketClient.open();
        return () => {
            socketClient.close();
        }
    }, [socketClient]);

    const noPadding = pathname === '/producers/herd-sense' || pathname === '/producers/vet-chat';

    const topbar = hideTopbar
        ? null
        : <Topbar navOpen={navOpen} setNavOpen={setNavOpen} />;

    return (
        <SocketContext.Provider value={socketClient}>
            {topbar}
            <section
                id="mainContent"
                className={`producer ${noPadding ? 'noPadding' : ''}`}
            >
                <Outlet />
            </section>
        </SocketContext.Provider>
    );
};

export default ProducerLayout;
