function PrivacyPolicy() {
  return (
    <div style={{ margin: 24 }}>
    <h2>Privacy Policy</h2>
    <h3>1. Introduction. </h3>
    <p>
Valmont Industries, Inc. makes the mobile applications (collectively the “Apps”), and websites (“Websites”), including, without limitation, Valley 365, Insights by Prospera, Valley Scheduling, Scheduling by Prospera, Valley Variable Rate Irrigation, Valley Irrigation Run Time, Valley Basestation3, SmartFarm, and AgSense (collectively, the “Services”) available for your use. We understand the importance and respect the privacy of everyone who uses the Services. In this Privacy Policy we refer to Valmont Industries, Inc. as “Valmont”, “we”, “us” or “our,” and we refer to the user as “you” and “your”. 
</p>
<p>
This policy explains how we collect, process and store any personal data belonging to you, meaning information by which you may be personally identified, such as your name, address, telephone number or email address. Such information is referred to throughout this policy as “Personal Information”.  
</p>
<p>
This policy applies only to information we collect through the Apps, the Websites, email, text, and other electronic communications sent through or in connection with the Apps and the Websites. This policy DOES NOT apply to information that:  
we collect offline; or 
you provide to or is collected by any third party (see Section 7 (Third-Party Information Collection)). 
These third parties may have their own privacy policies, which we encourage you to read before providing information through them. 
</p>
<p>
Please read this policy carefully to understand our policies and practices regarding your Personal Information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use our Apps or Websites. By downloading, registering with or using our Apps or Websites, you consent to the terms of this Privacy Policy. This policy may change from time to time (see Section 14 (Changes and Updates to this Privacy Policy)). Your continued use of our Apps or Websites after we make changes is deemed to be your consent to those changes (except to the extent that your express consent to any revisions is required under data protection law), so please check the policy periodically for updates. 
</p>
<p>
To ask questions or comment about this Privacy Policy and our privacy practices, contact us at +1 (888) 809-0230 or  Customer Support.
</p>
<h3>2. Types of Information Valmont Collects. </h3>
<p>
Valmont collects information from and about users of our Apps and Websites directly from you when you provide it to us, and automatically when you use the Apps or Websites. 
</p>
<p>
The information we collect through our Services include the following: 
</p>
<p>
User Profile Information:  When you download, register with, or use the Apps or Websites, we may ask that you provide information by which you may be personally identified and information that is about you but individually does not identify you. Such information may include your first and last name, address, email, phone number, username, password, mobile device information, and other contact information and other relevant data about your use of our Services. 
</p>
<p>
Cookies and Web Beacons:  In order to collect or use the data described herein, we may use temporary cookies that remain on your device’s cookies file for a limited period of time. We may also use persistent cookies that remain on your device’s cookies file until the cookies are removed via your browser’s settings, in order to manage and maintain the Services, record your use of the Services and content you may have gained access to, and to understand the usage trends and preferences of our users. Cookies by themselves cannot be used to discover the identity of the user. A cookie is a small piece of information which is sent to and stored on your device. Cookies do not damage your device. Most browsers enable you to block cookies, but you may not be able to use some features on the Services if you block them. You may set most browsers to notify you if you receive a cookie (this enables you to decide if you want to accept it or not). We may also use web beacons via the Services to collect information. Web beacons or “gifs” are electronic images that may be used in our Services or in our emails. We use web beacons to deliver cookies, to count visits and to tell if an email has been opened and acted upon.  We may also use third-party technologies and various third-party providers to process and analyze your Personal Information.  For example, we use Google Analytics on our Websites to collect information to improve the Services. Google Analytics places a cookie on your web browser so that it can identify you the next time you visit the Websites, but the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to the Websites is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. 
</p>
<p>
IP Address:  We use your IP address to help with server problem diagnosis and to administer the Services. An IP address is a numeric code that identifies your device on a network or the Internet. Your IP address is also used to gather broad demographic information. We may also perform IP lookups to determine which domain you are coming from to more accurately gauge our users’ demographics. We use all of the Personal Information that we collect to understand the usage trends and preferences of our users. 
</p>
<p>
Usage Information: When you use our Apps or Websites our servers may automatically record information about your use of our Service, including the type or version of the operating system, the browser, App version, CPU, memory, battery, access times, time zone, device ID, internet service provider, search history, regional and language settings and software and hardware attributes, mobile network information, and network connectivity and status (Wi-Fi/cellular carrier).  Our servers may also automatically record and store technical information regarding the method and nature of your use of the Services, including without limitation which pages or widgets of the Services you viewed, exit and entrance pages, network calls, logs, web views and any actions you make in the Services (such as taps, button presses, swipes, and user flows), and your use time. 
</p>
<p>
Geolocation Information: We collect GPS and other location data from devices, such as the physical location of your device (if you have permitted your location-aware device to transmit location data). When you take and upload photos through the Services we collect location data. We may use your location to also learn how to adjust and personalize your use of the Services. 
</p>
<p>
Payment Information: We will collect certain Personal Information necessary to complete your payment transactions. This information may include your email address, billing addresses, credit card information, and order history. We have engaged an independent third-party payment processor that helps us securely processes your payments. We do not store your financial or credit card numbers. 
</p>
<h3>3. Information Valmont Collects from Other Sources. </h3>
<p>
We may also obtain information from other sources and combine that with information we collect through our Services. We may also collect information from app stores when you download or update our Apps.   
</p>
<h3>4. Use of Information. </h3>
<p>
We will use your information for various purposes, including but not limited to the following purposes: 
</p>
<p>
Registering you as a user. <br/>
To send push notifications. <br/>
For our own legitimate business purposes and needs. <br/>
To administer your account(s). <br/>
To send you Service-related communications. <br/>
To respond to your requests and feedback. <br/>
To analyze and/or improve our Services. <br/>
To understand the usage trends and preferences of our users. <br/>
For marketing and customer relations purposes. <br/>
To personalize the way our content is presented to you online. <br/>
To investigate, prevent, or take action regarding illegal activities (including copyright infringement), suspected fraud, violations of the Apps or Website or any policies thereof or as otherwise required by law. <br/>
To train and develop machine learning algorithms. <br/>
To block disruptive users and protect the safety of users of our Services. <br/>
To enforce our Terms of Use. <br/>
To seek your feedback or to contact you in relation to the services offered on our Apps and Websites. <br/>
To send you, from time to time, updates. <br/>
To anticipate and resolve problems related to our Services. <br/>
To inform you of any changes to our Apps or Websites, or to send you additional information about us or our Apps or Websites. <br/>
To respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims. <br/>
To protect and defend the rights or property of Valmont or the users of our Apps or Websites. <br/>
In addition, when you send an email or other communication to Valmont, we may retain those communications in order to process your inquiries, respond to your requests and improve our Services. We may send you push notifications to send you news and update related emails and notifications in respect of the Services. We may also send you promotional communications if you joined our mailing list. You may opt-out of this service at any time by submitting a request. <br/>
</p>
<h3>5. Disclosure of Information. </h3>
<p>
We may share your Personal Information with third parties as set out in this Privacy Policy as follows: 
</p>
<p>
If applicable, with vendors, consultants and other service providers who need access to such information to carry out work on our behalf, including for processing of payments and debugging issues in the Services to improve the end-user experience.  We may share screenshots containing your Personal Information with such service providers for diagnosing the cause of an issue. 
In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation or legal process. 
If we believe your actions are inconsistent with the spirit or language of our Terms of Use or Privacy Policy or to protect the rights, property and safety of Valmont, our users or others. 
In the event that there is a sale of the assets of Valmont, any merger, financing or acquisition of all or portion of our business by another company, including sales in bankruptcy or liquidation, any third party to which Valmont transfers or sells Valmont’s assets will have the right to continue to use the personal and other information that you provide us in the manner set out in this Privacy Policy. 
We have not Sold or Shared your Personal Information for a Business Purpose in the preceding 12 months (as such terms are defined under California privacy law).   
</p>
<h3>6. Aggregate and Analytical Data. </h3>
<p>
In an ongoing effort to better understand and serve the customers of the Services, we often conduct research on our customer demographics, interests and behavior, use of the Services, and other statistics, based on the Personal Information and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and we may share this aggregate data with our affiliates, agents and business partners. This aggregate information does not identify you or any other individual personally. We may also disclose aggregated user statistics in order to describe our Services to current and prospective business partners, and to other third parties for other lawful purposes. 
</p>
<h3>7. Third-Party Information Collection. </h3>
<p>
When you use the Apps or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties include the following: 
</p>
<p>
your mobile device manufacturer; and 
your mobile service provider.  
 These third parties may use tracking technologies to collect information about you when you use our Apps. The information they collect may be associated with your Personal Information, or they may collect information, including Personal Information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. 
</p>
<p>
We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.  
</p>
<h3>8. Retaining your Information. </h3>
<p>
Valmont will retain your Personal Information as long as necessary to fulfill the purposes for which it was collected or as needed to provide you with Services. <br/>
We may retain your information after you have stopped using the Services or closed your account or after we have ceased providing Services to you if it is reasonably necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes between users, prevent fraud or abuse, or enforce the Privacy Policy or any other agreement we may have with a user. 
</p>
<h3>9. Protecting your Information. </h3>
<p>
Valmont values the importance of protecting your Personal Information and maintaining appropriate safeguards to preserve the integrity and security of your Personal Information and protect against accidental or unlawful destruction or loss, alteration or unauthorized disclosure or access. We may post a notice on our Websites if a security breach occurs. <br/>
We use the same level of safeguarding measures to protect your Personal Information as other similar app and website providers that host their data in the cloud. <br/>
We cannot guarantee any information that you transmit via our Apps or Websites when accessed over the Internet (Wi-Fi) network or cellular network will not be accessed, disclosed, altered or destroyed by breach of any of our safeguards. <br/>
We recommend you take utmost care when connecting over free public Wi-Fis and understand it might not be completely a secure environment and that you do so at your own risk. 
</p>
<h3>10. Processing of Information. </h3>
<p>
By providing any Personal Information to us pursuant to this Privacy Policy, all users, including, without limitation, users in the United States, Israel and member states of the European Union, fully understand and unambiguously consent to this Privacy Policy and to the collection, usage, and/or processing of such Personal Information abroad. The server on which the Services are hosted and/or through which the Services are processed may be outside the country from which you access the Services and may be outside your country of residence. Some of the uses and disclosures mentioned in this Privacy Policy may involve the transfer of your Personal Information to various countries around the world that may have different levels of privacy protection than your country and may be transferred outside of the European Economic Area (EEA). If there is a transfer of your Personal Information outside the EEA we will, in the absence of an EC Adequacy decision relevant to the destination country or to the transfer, enter into appropriate EC approved standard contractual clauses (see https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en). By submitting your Personal Information through the Services, you consent and acknowledge that we may collect, use, transfer, and disclose your Personal Information as described in this Privacy Policy. 
</p>
<h3>11. Your Choices </h3>
<p>
If you do not agree with our Privacy Policy or Terms of Use, please delete your account, uninstall our Apps and discontinue use of the Services. <br/>
Your continued usage of our Services will signify your consent to our Privacy Policy and acceptance of our Terms of Use. <br/>
You can contact us anytime at Customer Support to modify, correct or remove information to keep your Personal Information accurate and up to date. <br/>
Any information that you have shared with any third-party platform via the Services cannot be amended or deleted by us where it is in the control of that third party platform. <br/>
You have the right to know what Personal Information we collect about you and to ensure that such data is accurate and relevant for the purposes for which we collected it. We allow our users the option to access and obtain a copy of their Personal Information and to rectify such Personal Information if it is not accurate, complete or updated.  However, we may first ask you to provide us certain credentials to permit us to identify your Personal Information.  You have the right to  delete your Personal Information or restrict its processing. We may postpone or deny your request if your Personal Information is in current use for the purposes for which it was collected or for other legitimate purposes such as compliance with legal obligations. You have the right to withdraw your consent to the processing of your Personal Information. Exercising this right will not affect the lawfulness of processing your Personal Information based on your consent before its withdrawal.  Where technically feasible, you have the right to ask to transfer your Personal Information in accordance with your right to data portability. You may exercise the above rights by sending a request to Customer Support. You also have the right to lodge a complaint with a data protection supervisory authority regarding the processing of your Personal Information. 
</p>
<h3>12. Children’s Privacy. </h3>
<p>
The Services are not directed to or intended for use by minors. Consistent with the requirements of the U.S. Children’s Online Privacy Protection Act, if we learn that we have received any Personal Information directly from a child under age 13 without his or her parent’s verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Services. Subsequently, we will delete that information. 
</p>
<h3>13. “Do Not Track” Signals. </h3>
<p>
California’s Online Privacy Protection Act requires website operators to disclose how they respond to web browser’s “Do Not Track” (“DNT”) signals. Some web browsers incorporate a DNT or similar feature that signals to websites that a user does not want to have his or her online activity tracked. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many website operators, including Valmont, do not respond to DNT signals. 
</p>
<h3>14. Changes and Updates to this Privacy Policy. </h3>
<p>
We may change this Privacy Policy from time to time. If we make material changes, we will notify you by revising the date at the top of the policy and, depending on the specific changes, we may provide you with additional notice or require a renewed consent (e.g., by adding a statement to a Website’s homepage or sending you a push notification or email). We encourage you to review the Privacy Policy whenever you access our Services to stay informed about our information practices and the ways you can help protect your privacy. 
</p>
<p>
This Privacy Policy will help you to make an informed decision about whether to use or continue using the Services. If you do not consent to our Privacy Policy, please do not use the Apps or Websites. 
</p>
<h3>15. California Privacy Law.</h3>
<p>
This section applies specifically to California consumer residents under the California Consumer Privacy Act and the California Privacy Rights Act, as may be amended. 
</p>
<p>
As indicated in these California privacy laws – 
</p>
<p>
A consumer shall have the right to request that a business delete any personal information about the consumer which the business has collected from the consumer.  <br/>
A consumer shall have the right to request a business that maintains inaccurate personal information about the consumer to correct that inaccurate personal information. <br/>
A consumer shall have the right to request that a business that collects personal information about the consumer disclose to the consumer the following: (1) the categories of personal information it has collected about that consumer; (2) the categories of sources from which the personal information is collected; (3) the business or commercial purpose for collecting, or selling, or sharing personal information; (4) the categories of third parties with whom the business shares personal information; and (5) the specific pieces of personal information it has collected about that consumer. <br/>
A consumer shall have the right to request that a business that sells or shares the consumer’s personal information, or that discloses it for a business purpose, disclose to that consumer: (1) the categories of personal information that the business collected about the consumer; (2) the categories of personal information that the business sold or shared about the consumer and the categories of third parties to whom the personal information was sold or shared, by category or categories of personal information for each category of third parties to whom the personal information was sold or shared; and (3) the categories of personal information that the business disclosed about the consumer for a business purpose and the categories of persons to whom it was disclosed for a business purpose. <br/>
To exercise the rights identified above, please submit a verifiable consumer request to us by either using our web form or calling our U.S. toll free number +1 (888) 809-0230. After you submit a request that provides sufficient information that allows us to reasonably verify you are the person about whom we collected personal information (or an authorized representative) and that describes your request with sufficient detail that allows us to properly understand, evaluate, and respond to it, we will process or otherwise respond to your request. We will not discriminate against you for exercising any of the above rights.<br/>
    </p>
    </div>
  );
}

export default PrivacyPolicy;
