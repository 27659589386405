import React, {useState, useRef} from 'react';
import moment from 'moment';

import Viewer from '../../components/Viewer/Modal';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Loader from '../../components/Loader/Loader';
import RemoveModal from '../../components/RemoveModal';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import Client from '../../apis/Client';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import MonitoringAreaModal from './MonitoringAreaModal';

import css from '../styles.module.scss';

function Poles(props) {
  const { location } = props;

  const locationId = location?.id;
  const companyId = location?.company_id;

  const activeArea = useRef({});

  const [showRemoveArea, setShowRemoveArea] = useState(false);
  const [showEditArea, setShowEditArea] = useState(false);

  const {
    data: poles,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['location_monitoring_areas', locationId],
    queryFn: () => Client.get(`/api/v1/admin/locations/${locationId}/monitoring-areas`)
  });

  const { mutateAsync: removeArea } = useMutation({
    mutationKey: 'delete_device',
    mutationFn: () => Client.delete(`/api/v1/admin/monitoring-areas/${activeArea.current.id}`),
    onSuccess: () => {
      setShowRemoveArea(false);
      refetch();
    }
  });

  const columnsInfo = [
    { name: 'name', label: 'Name' },
    { name: 'id', label: 'Area ID' },
    {
      name: 'created_at',
      label: 'Created',
      valFn: (val) => {
        return moment.utc(val).local().format('MM/DD/YYYY');
      },
    },
    {
      name: 'deleteBtn',
      label: '',
      width: 87,
      componentFn: (pole) => (
        <button
          type="button"
          className={`btn btn-outline-danger ms-sm-auto me-sm-auto me-lg-0`}
          onClick={(e) => {
            e.stopPropagation();
            activeArea.current = { ...pole };
            setShowRemoveArea(true);
          }}
        >
          Delete
        </button>
      )
    },
  ];

  if (isError) {
    return <h4 style={{color: 'red'}}>{error.msg}</h4>;
  }

  return (
    <>
      <div id="tableContain" className={`bgWhite tableContain`}>
        <PageSubTitle title="Monitoring Areas" />
        <div style={{ width: '100%', height: 'calc(200px)' }}>
          <CustomTable
            columns={columnsInfo}
            rows={poles || []}
            rowKey="id"
            onRowClick={(e, row) => {
              activeArea.current = { ...row };
              setShowEditArea(true);
            }}
          />
        </div>
      </div>

      {showEditArea && (
        <MonitoringAreaModal
          area={activeArea.current}
          locationId={locationId}
          companyId={companyId}
          showModal={showEditArea}
          setShowModal={setShowEditArea}
          refetch={refetch}
        />
      )}

      <AlertModal
        show={showRemoveArea}
        onAccept={removeArea}
        onCancel={() => setShowRemoveArea(false)}
        title="Remove Monitoring Area"
        btn1="No"
        btn2="Yes"
        message="Would you like to remove this monitoring area?"
      />
    </>
  );
}

export default Poles;
