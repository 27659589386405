import React from 'react';

import css from './styles.module.scss';

function PaneSelect(props) {
  const {
      label,
      name,
      formik,
      required,
      className,
      message,
      disabled,
      options = [],
      placeholder,
      onBlur,
      onChange,
      value
    } = props;

  const touched = formik?.touched[name];
  const error = touched ? formik?.errors[name] : null;

  const textClass = error ? css.errorText : ''
  const inputClass = error ? css.errorInput : ''

  const optionEls = options.map((opt) => (
    <option key={opt.value} value={opt.value}>{opt.label}</option>
  ));

  const inputEl = (
    <select
      id={name}
      name={name}
      className={`w-100 ${inputClass}`}
      disabled={disabled}
      placeholder={placeholder}
      onBlur={formik?.handleBlur || onBlur}
      onChange={formik?.handleChange || onChange}
      value={formik?.values[name] || value}
    >
      {optionEls}
    </select>
  )

  const asterisk = required ? <span className={css.reqAsterisk}>*</span> : null;

  return (
    <div className={`${className}`}>
      <div className={css?.inner}>
        <label htmlFor={name}>{label}{asterisk}</label>
        {inputEl}
      </div>
      <span className={`${css.messageTxt} ${textClass}`}>{error || message}</span>
    </div>
  );
}

export default PaneSelect;
