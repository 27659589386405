export const required = (val) => {
    if (val === undefined || null) {
        return null
    }
    return val.trim().length < 1 ? 'Field is required' : null
}

export const phone = (val) => {
    return !/^(\+\d+)? ?(\d{3}|\(\d{3}\))( |-)?\d{3}( |-)?\d{4}$/.test(val)
        ? 'Must be a valid phone number'
        : null
}

export const email = (val) => {
    return !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val)
        ? 'Must be a valid email'
        : null
}

export const password = (val) =>
    val.length > 0 && val.length < 6
        ? 'Must be at least 6 characters long'
        : null

export const isNumber = (val) =>
    isNaN(Number(val)) ? 'Must be a number' : null
