import React from 'react';
import {Modal} from 'react-bootstrap';
import { Formik, Form } from 'formik';

import { required } from '../../validation/form';

import Loader from '../../components/Loader/Loader';
import FormModal from '../../components/FormModal';
import CustomInput from '../../components/forms/CustomInput';

import Client from '../../apis/Client';

import useMutation from '../../hooks/useMutation';

import css from '../styles.module.scss';

const validate = (values) => {
	const errors = {};
 
	required(errors, values, 'name');

	return errors;
};

function MonitoringAreaModal(props) {
	const {
		showModal,
		setShowModal,
		companyId,
		locationId,
		refetch,
		area
	} = props;

	const initialValues = {
		name: area.name
	};

	const {
		mutateAsync: onSubmit,
		isLoading,
		isError,
		error,
	} = useMutation({
		mutationKey: 'create-edit-device',
		mutationFn: (body) => {
			let url = `/api/v1/admin/monitoring-areas/${area.id}`;
			return Client.put(url, body);
		},
		onSuccess: (values) => {
			setShowModal(false);
			refetch();
		}
	});

	const headerLabel = `Update Monitoring Area ${area.name}`;

	return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={headerLabel}
          submitBtnLabel="Update"
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <CustomInput
                    label="Name"
                    name="name"
                    formik={formik}
                    required
                />
            )}
        </FormModal>
    );
}

export default MonitoringAreaModal;
