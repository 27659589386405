import React from 'react';
import { Formik, Form } from 'formik';
import {Modal} from 'react-bootstrap';

import Client from '../../apis/Client';

import {required, latitude, longitude, noSpaces} from '../../validation/form';

import Loader from '../../components/Loader/Loader';
import CustomTable from '../../components/CustomTable';

import { copyToClipboard } from '../../util';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import css from '../styles.module.scss';

const specialHeartBeatCols = [
  {
    name: 'timestamp',
    label: 'Timestamp',
    valFn: (datum) => new Date(datum.created_at).toLocaleString()
  },
  {
    name: 'device_id',
    label: 'Device ID',
  }
];

const DeviceModal = ({
  setShowModal = () => {},
  show,
  device = {}
}) => {
  const {data: heartBeats, isLoading, isError, error} = useQuery({
    queryKey: ['heart-beats'],
    queryFn: () => Client.get(`/api/v1/admin/devices/${device.id}/heartbeats`),
    enabled: !!show
  });

  if (!show) {
    return null;
  }

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md"
      centered
      show={show}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
          <Modal.Title>Heartbeats</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ width: '100%', height: '300px' }}>
          <CustomTable
            columns={specialHeartBeatCols}
            rows={heartBeats || []}
            rowKey="id"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={`align-items-center d-flex justify-content-between border-0 bgGray246`}>
          <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowModal(false)}>
              Close
          </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeviceModal;
