import React, {useState, useRef} from 'react';

import Viewer from '../../components/Viewer/Modal';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Loader from '../../components/Loader/Loader';
import RemoveModal from '../../components/RemoveModal';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import Client from '../../apis/Client';

import DeviceModal from './DeviceModal';
import HeartbeatModal from './HeartbeatModal';

import css from '../styles.module.scss';

function Devices(props) {
  const { location } = props;

  const locationId = location?.id;
  const companyId = location?.company_id;

  const [selectedDevice, setSelectedDevice] = useState(null);
  const viewerRef = useRef();

  const [showCreateEditDevice, setShowCreateEditDevice] = useState(false);
  const [showRemoveDevice, setShowRemoveDevice] = useState(false);
  const [showLiveView, setShowLiveView] = useState(false);
  const [showHeartbeats, setShowHeartbeats] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showClearAlert, setShowClearAlert] = useState(false);

  const { mutateAsync: updateDeviceMonitoring } = useMutation({
    mutationKey: 'put_device_monitoring',
    mutationFn: ({ deviceId, payload }) => Client.put(`/api/v1/admin/devices/${deviceId}/monitoring`, payload),
    onSuccess: () => {
      refetch();
    }
  });

  const {
    data: devices,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['location_devices', locationId],
    queryFn: () => Client.get(`/api/v1/admin/locations/${locationId}/devices`)
  });

  const {
    data: unmarkedDevices,
    isLoading: isLoadingUnmarked,
    error: unmarkedError,
    refetch: refetchUnmarked
  } = useQuery({
    queryKey: ['unmarked_devices', locationId],
    queryFn: () => Client.get(`/api/v1/admin/devices/unmarked`)
  });

  const { mutateAsync: removeDevice } = useMutation({
    mutationKey: 'delete_device',
    mutationFn: () => Client.delete(`/api/v1/admin/devices/${selectedDevice.id}`),
    onSuccess: () => {
      setShowDeleteAlert(false);
      refetch();
    }
  });

  const { mutateAsync: clearIds, isLoading: isLoadingClearIds } = useMutation({
    mutationKey: 'clear_device_ids',
    mutationFn: () => Client.delete('/api/v1/mugs', { deviceId: selectedDevice.id }),
    onSuccess: () => {
      setShowClearAlert(false);
      refetch();
    }
  });

  function createNewDevice() {
    setSelectedDevice(null);
    setShowCreateEditDevice(true);
  }

  const columnsInfo = [
    { name: 'name', label: 'Name' },
    {
      name: 'latLong',
      label: 'Lat/Long',
      valFn: (val) => {
        return `${val.latitude ? val.latitude + ', ' : ''} ${
          val.longitude ? val.longitude : ''
        }`;
      },
    },
    { name: 'id', label: 'Device ID' },
    {
      name: 'monitoring_area',
      label: 'Monitoring Area',
      valFn: (val) => {
        return (val.monitoring_area || {}).name || '';
      },
    },
    {
      name: 'heartbeat_monitoring',
      label: 'Heartbeat Monitoring',
      width: 87,
      componentFn: (device) => {
        const className = device.is_monitored
          ? `btn btn-primary me-sm-auto me-lg-0`
          : `btn btn-outline-primary me-sm-auto me-lg-0`;
        return (
          <button
            type="button"
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              updateDeviceMonitoring({
                deviceId: device.id,
                payload: { monitor_heartbeat: !device.is_heartbeat_monitored }
              });
            }}
          >
            {device.is_heartbeat_monitored ? 'ON' : 'OFF'}
          </button>
        );
      }
    },
    {
      name: 'cattle_monitoring',
      label: 'Cattle Monitoring',
      width: 87,
      componentFn: (device) => {
        const className = device.is_monitored
          ? `btn btn-primary me-sm-auto me-lg-0`
          : `btn btn-outline-primary me-sm-auto me-lg-0`;
        return (
          <button
            type="button"
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              updateDeviceMonitoring({
                deviceId: device.id,
                payload: { monitor_cattle: !device.is_cattle_monitored }
              });
            }}
          >
            {device.is_cattle_monitored ? 'ON' : 'OFF'}
          </button>
        );
      }
    },
    {
      name: 'live_view',
      label: '',
      width: 87,
      componentFn: (device) => (
        <button
          type="button"
          className={`btn btn-outline-primary ms-sm-auto me-sm-auto me-lg-0`}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDevice({ ...device });
            setShowLiveView(true);
          }}
        >
          Live View 
          <div className={`${css.onlineIcon} ${device.deviceOnline ? css.active : ''}`}/>
        </button>
      )
    },
    {
      name: 'heartbeats',
      label: '',
      width: 87,
      componentFn: (device) => (
        <button
          type="button"
          className={`btn btn-outline-primary ms-sm-auto me-sm-auto me-lg-0`}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDevice({ ...device });
            setShowHeartbeats(true);
          }}
        >
          Heartbeats
        </button>
      )
    },
    {
      name: 'clear-device',
      label: '',
      width: 87,
      componentFn: (device) => {
        const className = `btn btn-outline-danger ms-sm-auto me-sm-auto me-lg-0`;
        return (
          <button
            type="button"
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDevice({ ...device });
              setShowClearAlert(true);
            }}
          >Clear IDs</button>
        );
      }
    },
    {
      name: 'deleteBtn',
      label: '',
      width: 87,
      componentFn: (device) => (
        <button
          type="button"
          className={`btn btn-outline-danger ms-sm-auto me-sm-auto me-lg-0`}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDevice({ ...device });
            setShowDeleteAlert(true);
          }}
        >
          Delete
        </button>
      )
    },
  ];

  const unmarkedColumnsInfo = [
    { name: 'id', label: 'Device ID' },
    {
      name: 'latLong',
      label: 'Lat/Long',
      valFn: (val) => {
        return `${val.latitude ? val.latitude + ', ' : ''} ${
          val.longitude ? val.longitude : ''
        }`;
      },
    },
    {
      name: 'addBtn',
      label: '',
      width: 87,
      componentFn: (device) => (
        <button
          type="button"
          className={`btn btn-outline-primary ms-sm-auto me-sm-auto me-lg-0`}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDevice({ ...device });
            setShowCreateEditDevice(true);
          }}
        >
          Add to location
        </button>
      )
    },
  ];

  if (isError) {
    return <h4 style={{color: 'red'}}>{error.msg}</h4>;
  }

  return (
    <>
      <div id="tableContain" className={`bgWhite tableContain`}>
        <PageSubTitle title="Devices" />
        <div style={{ width: '100%', height: 'calc(200px)' }}>
          <CustomTable
            defaultSortCol="name"
            columns={columnsInfo}
            rows={devices || []}
            rowKey="id"
            onRowClick={(e, row) => {
              setSelectedDevice({ ...row });
              setShowCreateEditDevice(true);
            }}
          />
        </div>

        <h4>Unassociated Devices:</h4>
        <div style={{ width: '100%', height: 'calc(200px)' }}>
          <CustomTable
            defaultSortCol="id"
            columns={unmarkedColumnsInfo}
            rows={unmarkedDevices || []}
            rowKey="id"
          />
        </div>
        <button onClick={createNewDevice}>Add New</button>
      </div>

      {showLiveView && (
        <Viewer
          ref={viewerRef}
          deviceId={selectedDevice.id}
          deviceIP={selectedDevice.device_ip}
          isJSmpeg={selectedDevice?.is_rtsp}
          className={css.liveViewContainer}
          showIds={false}
          isDebugView={false}
          setShowViewer={setShowLiveView}
          showViewer={showLiveView}
        />
      )}

      <DeviceModal
        device={selectedDevice}
        locationId={locationId}
        companyId={companyId}
        showModal={showCreateEditDevice}
        setShowModal={setShowCreateEditDevice}
        onSuccess={(rsp) => {
          setSelectedDevice(null);
          refetch();
          refetchUnmarked();
        }}></DeviceModal>

      <HeartbeatModal
        device={selectedDevice}
        show={showHeartbeats}
        setShowModal={setShowHeartbeats}
      />

      <AlertModal
        onAccept={removeDevice}
        onCancel={() => setShowDeleteAlert(false)}
        show={showDeleteAlert}
        title="Remove Device"
        btn1="No"
        btn2="Yes"
        message="Would you like to remove this device?"
      />

      <AlertModal
        onAccept={clearIds}
        onCancel={() => setShowClearAlert(false)}
        isLoading={isLoadingClearIds}
        show={showClearAlert}
        title="Clear IDs"
        btn1="No"
        btn2="Yes"
        message="Would you like to clear all IDs this device?"
      />
    </>
  );
}

export default Devices;
