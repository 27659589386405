import { default as drinkSvg } from './drink';
import { default as eatSvg } from './eat';
import { default as idleSvg } from './idle';
import { default as layingSvg } from './laying';
import { default as runSvg } from './run';
import { default as sitSvg } from './sit';
import { default as standSvg } from './stand';
import { default as trotSvg } from './trot';
import { default as urinateSvg } from './urinate';
import { default as walkSvg } from './walk';

function loadSvgToBase64(svgStr) {  
  const header = 'data:image/svg+xml;base64,';

  const base64 = btoa(svgStr);

  const icon = `${header}${base64}`;

  const img = new Image();
  img.src = icon;

  return img;
}

export const drinkIcon = loadSvgToBase64(drinkSvg);
export const eatIcon = loadSvgToBase64(eatSvg);
export const idleIcon = loadSvgToBase64(idleSvg);
export const layingIcon = loadSvgToBase64(layingSvg);
export const runIcon = loadSvgToBase64(runSvg);
export const sitIcon = loadSvgToBase64(sitSvg);
export const standIcon = loadSvgToBase64(standSvg);
export const trotIcon = loadSvgToBase64(trotSvg);
export const urinateIcon = loadSvgToBase64(urinateSvg);
export const walkIcon = loadSvgToBase64(walkSvg);
