import React, { useContext } from 'react';
import {
  Outlet,
  Navigate,
  useLocation
} from 'react-router-dom';

import NotFound from '../NotFound';
import { getDefaultPath, hasRoutePermissions } from '../util';

import {
  LOGIN_PATH,
  NOT_FOUND_PATH,
} from '../constants';

import AuthContext from '../context/AuthContext';

function ProtectedLayout(props) {
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return <Navigate to={LOGIN_PATH} replace></Navigate>;
  }

  const hasAccessToRoute = hasRoutePermissions(userData?.roles, props.portalDefaultPath);
  if (!hasAccessToRoute) {
    const defaultPath = getDefaultPath(userData?.roles);
    return <Navigate to={defaultPath} replace></Navigate>;
  }

  return <Outlet></Outlet>;
};

export default ProtectedLayout;
