import React from 'react';

import css from './styles.module.scss';

function PaneInput(props) {
  const {
    label,
    name,
    className,
    message,
    disabled,
    type,
    placeholder,
    formik,
    required
  } = props;

  const error = formik.touched[name] && formik.errors[name];

  const textClass = error ? css.errorText : '';

  const inputEl = (
    <input
      type={type}
      id={name}
      className={css.form__field}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.companyName}
    />
  );

  const asterisk = required ? <span className={css.reqAsterisk}>*</span> : null;

  return (
    <div className={`${className}`}>
      <div className={css?.inner}>
        <label htmlFor={name}>{label}{asterisk}</label>
        {inputEl}
      </div>
      <span className={`${css.messageTxt} ${textClass}`}>{error || message}</span>
    </div>
  );
}

export default PaneInput;
