import { useContext, useState } from 'react';
import { useQuery as reactQueryUseQuery } from '@tanstack/react-query';

import AuthContext from '../context/AuthContext';

export default function useQuery(props) {
  const { runProtected } = useContext(AuthContext);

  const [fetched, setFetched] = useState(false);

  const { onRefetch, onFetch, isProtected = true } = props;

  const query = reactQueryUseQuery({
    ...props,
    queryFn: async (...args) => {
      let data = null;
      if (isProtected) {
        data = await runProtected(props.queryFn, ...args);
      } else {
        data = await props.queryFn(...args);
      }

      if (onRefetch) onRefetch(data);
      if (!fetched && onFetch) {
        onFetch(data);
        setFetched(true);
      }

      return data;
    },
  });

  return query;
}
