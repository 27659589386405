import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

export default class Client {
    static accessToken() {
        return this.getTokenDecoded('herdsense_token');
    }

    static getToken(name) {
        const cookies = new Cookies();
        return cookies.get(name);
    }

    static clearToken(name) {
        const cookies = new Cookies();
        const cookie = cookies.get(name);
        if (cookie) {
            document.cookie = name + '=; Max-Age=-99999999;';
        }
    }

    static getHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    static getTokenDecoded(name) {
        const token = Client.getToken(name);

        if (token) {
            return jwtDecode(token);
        }
    }

    static async get(url) {
        const options = {
            method: 'GET',
            headers: this.getHeaders(),
        };
        const response = await fetch(url, options);
        try {
            return await this.parseResponse(response);
        } catch (e) {
            throw e;
        }
    }

    static parseResponse = async (response) => {
        const body = await response.json();
        if (!response.ok) {
            const err = new Error(body.error);
            err.status = response.status;
            throw err;
        }
        return body;
    };

    static async post(url, data, authorization) {
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        };

        if (authorization) {
            options.headers.Authorization = `Bearer ${authorization}`;
        }

        try {
            const response = await fetch(url, options);
            return this.parseResponse(response);
        } catch (e) {
            throw e;
        }
    }

    static async put(url, data = {}) {
        const options = {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        };

        const response = await fetch(url, options);
        try {
            return await this.parseResponse(response);
        } catch (e) {
            throw e;
        }
    }

    static async delete(url, data = {}) {
        const options = {
            method: 'DELETE',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        };
        const response = await fetch(url, options);
        try {
            return await this.parseResponse(response);
        } catch (e) {
            throw e;
        }
    }

    static async multipart(url, formdata) {
        const options = {
            method: 'POST',
            body: formdata,
        };

        const response = await fetch(url, options);
        const body = await response.json();
        if (!response.ok) {
            const err = new Error(body.error);
            err.status = response.status;
            throw err;
        }

        return { response, body };
    }

    static getCookieOptions(domain) {
        //if (domain !== "127.0.0.1" && domain !== "localhost") {

        // Assumes that the suffix doesn't have a dot in it. In other words, assuming it's not "co.uk" or something
        // like it. If we need to support that case then we could get a list of public suffixes.

        const lastDotIndex = domain.lastIndexOf('.');
        const secondToLastDotIndex = domain.lastIndexOf('.', lastDotIndex - 1);

        const base = domain.slice(secondToLastDotIndex + 1);
        domain = '.' + base;
        //}
        const options = domain ? { path: '/', domain } : { path: '/' };

        options.sameSite = 'strict';

        return options;
    }
}
