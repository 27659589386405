import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query'
import Client from '../apis/Client'
import HomeFooter from '../Home/Components/HomeFooter/HomeFooter'
import HomeProducers from '../Home/HomeProducers'
import AcceptModal from './AcceptModal'
import css from '../Home/styles.module.scss'
import { LOGIN_PATH } from '../constants'

function ResetPassword({ type }) {
    const { key } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    //const type = location.pathname.split()
    const searchParm = new URLSearchParams(location.search)

    const {
        mutate: reset,
        isLoading,
        isError,
        error,
    } = useMutation({
        mutationKey: 'accept-invite',
        mutationFn: (password) => Client.post('/api/v1/auth/reset-password', { key, password }),
        onSuccess: () => {
            navigate(LOGIN_PATH);
        },
    });

    let errorMsg = null;
    if (error) {
        switch (error.status) {
            case 400:
                errorMsg = 'Password reset request has expired, please send a new request.';
                break;
            default:
                errorMsg = 'Sorry, there was an error processing your request. Please try again later.'
        }
    }

    return (
        <main id="top" className={`${css.main} ${css.bgGray243}`}>
            <AcceptModal
                title="Reset Password"
                message="Please enter a new password."
                onAccepted={reset}
                type={type}
                error={errorMsg}
            />
            <HomeProducers />
            <HomeFooter />
        </main>
    )
}

export default ResetPassword
