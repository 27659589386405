const str = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M6.40944 6.46567C6.32507 6.84532 6.28216 7.24045 6.28568 7.64598C6.2892 8.05152 6.33895 8.44584 6.42989 8.82397L6.43076 8.92513C4.56179 8.94133 3.03808 7.42288 3.02189 5.55461C3.01523 4.78672 3.26944 4.0335 3.74408 3.42837L3.9389 3.18002C4.06529 3.01878 4.28905 2.9716 4.4697 3.06805C4.65034 3.16449 4.73588 3.37691 4.67288 3.57189L4.38643 4.45845C4.3224 4.65661 4.30099 4.86631 4.32337 5.07325C4.40544 5.82911 5.04458 6.39761 5.80152 6.39105L6.40875 6.38578L6.40944 6.46567Z" fill="#F6F6F6"/>
<path d="M12.7065 6.46567C12.7909 6.84532 12.8338 7.24045 12.8303 7.64598C12.8268 8.05152 12.777 8.44584 12.6861 8.82397L12.6852 8.92513C14.5542 8.94133 16.0779 7.42288 16.0941 5.55461C16.1007 4.78672 15.8465 4.0335 15.3719 3.42837L15.1771 3.18002C15.0507 3.01878 14.8269 2.9716 14.6463 3.06805C14.4656 3.16449 14.3801 3.37691 14.4431 3.57189L14.7295 4.45845C14.7936 4.65661 14.815 4.86631 14.7926 5.07325C14.7105 5.82911 14.0714 6.39761 13.3145 6.39105L12.7072 6.38578L12.7065 6.46567Z" fill="#F6F6F6"/>
<path d="M10.3918 12.8616C9.99634 13.4588 9.11975 13.4588 8.7243 12.8616L5.45402 7.92346C5.01379 7.25871 5.49046 6.37132 6.28776 6.37132L12.8283 6.37132C13.6256 6.37132 14.1023 7.25871 13.6621 7.92346L10.3918 12.8616Z" fill="#F6F6F6"/>
<ellipse cx="9.55794" cy="6.54541" rx="2.81843" ry="0.70459" fill="#F6F6F6"/>
<circle cx="15.502" cy="14.1069" r="1.34668" fill="#F6F6F6"/>
<circle cx="13.5415" cy="17.3438" r="1.34668" fill="#F6F6F6"/>
<circle cx="17.4626" cy="17.3438" r="1.34668" fill="#F6F6F6"/>
</svg>
`

export default str;
