import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';

import AuthLayout from './layouts/AuthLayout';

import LoginRouter from './routes/LoginRouter';
import ProducerRouter from './routes/ProducerRouter';
import DeveloperRouter from './routes/DeveloperRouter';
import AdminRouter from './routes/AdminRouter';
import ReviewRouter from './routes/ReviewRouter';
import VetRouter from './routes/VetRouter';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './styles.scss';

import Home from './Home/Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

import NotFound from './NotFound';

// import WifiConfigBarcode from './components/WifiConfigBarcode/WifiConfigBarcode';
import DeviceAPIDocs from './components/DeviceAPIDocs/DeviceAPIDocs';
// import Email from './Email/Email';

const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />

          {/*<Route
            path="/wifi-config-barcode"
            element={<WifiConfigBarcode></WifiConfigBarcode>}></Route>*/}

          <Route
            path="/devices/api-docs"
            element={<DeviceAPIDocs></DeviceAPIDocs>}></Route>

          <Route path="/">
            {LoginRouter}
            {ProducerRouter}
            {DeveloperRouter}
            {AdminRouter}
            {ReviewRouter}
            {VetRouter}
          </Route>

          {/* BELOW: used to see emails when building */}
          {/* <Route path="email" element={<Email />} /> */}
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthLayout>
    </QueryClientProvider>
  );
}

export default App;
