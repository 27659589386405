const str = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M15.5212 16.4373C15.7026 17.135 15.284 17.8477 14.5862 18.0291C13.8885 18.2105 13.1758 17.7919 12.9944 17.0942C12.813 16.3965 13.3723 15.6201 13.6587 14.5445C14.5862 15.0923 15.3398 15.7396 15.5212 16.4373Z" fill="#F6F6F6"/>
<path d="M5.46201 16.9209V14.501C5.46201 12.8441 6.80515 11.501 8.46201 11.501H14.3716C15.3257 11.501 16.223 11.9548 16.7883 12.7235L17.8108 14.1137" stroke="#F6F6F6" stroke-width="3" stroke-linecap="round"/>
<path d="M8.13883 5.18207C8.19194 5.42105 8.21895 5.66975 8.21674 5.92502C8.21452 6.18029 8.18321 6.42849 8.12597 6.66651L8.12541 6.73018C9.30184 6.74038 10.2609 5.78459 10.2711 4.6086C10.2753 4.12526 10.1153 3.65114 9.81655 3.27024L9.69392 3.11392C9.61436 3.01242 9.47352 2.98273 9.35981 3.04344C9.2461 3.10414 9.19226 3.23785 9.23192 3.36058L9.41222 3.91863C9.45252 4.04336 9.46601 4.17535 9.45191 4.30561C9.40026 4.78139 8.99795 5.13923 8.5215 5.1351L8.13927 5.13179L8.13883 5.18207Z" fill="#F6F6F6"/>
<path d="M4.17513 5.18158C4.12202 5.42056 4.09501 5.66927 4.09723 5.92453C4.09944 6.1798 4.13076 6.428 4.188 6.66602L4.18855 6.72969C3.01213 6.73989 2.05302 5.7841 2.04283 4.60811C2.03864 4.12477 2.19865 3.65065 2.49741 3.26975L2.62004 3.11343C2.6996 3.01193 2.84045 2.98224 2.95415 3.04295C3.06786 3.10365 3.1217 3.23736 3.08205 3.36009L2.90174 3.91814C2.86144 4.04287 2.84796 4.17487 2.86205 4.30512C2.91371 4.7809 3.31602 5.13874 3.79247 5.13461L4.1747 5.1313L4.17513 5.18158Z" fill="#F6F6F6"/>
<path d="M5.63223 9.20803C5.88114 9.5839 6.43291 9.5839 6.68183 9.20803L8.74032 6.09969C9.01742 5.68126 8.71738 5.12269 8.21551 5.12269L4.09855 5.12269C3.59668 5.12269 3.29664 5.68126 3.57374 6.09969L5.63223 9.20803Z" fill="#F6F6F6"/>
<ellipse cx="1.77407" cy="0.443505" rx="1.77407" ry="0.443505" transform="matrix(-1 0 0 1 7.93091 4.78857)" fill="#F6F6F6"/>
</svg>
`

export default str;
