import React from 'react';
import { Formik, Form } from 'formik';
import {Modal} from 'react-bootstrap';
import useMutation from '../../hooks/useMutation';

import { required, routingNumber, accountNumber } from '../../validation/form';

import CustomInput from '../../components/forms/CustomInput';
import ErrorBox from '../../components/ErrorBox';

import ProducerClient from '../../apis/Producers';

const validate = (values) => {
    const errors = {};
 
    required(errors, values, 'accountName');
    required(errors, values, 'routingNumber');
    required(errors, values, 'accountNumber');

    routingNumber(errors, values, 'routingNumber');
    accountNumber(errors, values, 'accountNumber');

    return errors;
};

function PaymentModal(props) {
    const { showModal, setShowModal } = props;

    const initialValues = {
        accountName: '',
        routingNumber: '',
        accountNumber: ''
    };

    const { mutateAsync: addBankAccount, isLoading, isError, error } = useMutation({
        mutationKey: '',
        mutationFn: (data) => ProducerClient.addBankAccount(data),
        onSuccess: () => {
            setShowModal(false);
        }
    });

    const onSubmit = (values) => {
        addBankAccount(values)
    };

    return (
        <Modal
            size="l"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
            scrollable
        >
            <Modal.Header closeButton>
                <h3 className='modal-title'>Add Bank Account</h3>
            </Modal.Header>

            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form className={`d-inline`}>
                        <Modal.Body>
                            <CustomInput
                                label="Account Name"
                                name="accountName"
                                formik={formik}
                            />
                            <CustomInput
                                label="Routing Number"
                                name="routingNumber"
                                formik={formik}
                            />
                            <CustomInput
                                label="Account Number"
                                name="accountNumber"
                                formik={formik}
                            />

                            <ErrorBox show={isError} message={error?.message} />
                        </Modal.Body>

                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default PaymentModal;
