const str = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" fill="#0D1D27"/>
<rect x="0.5" y="0.5" width="21" height="21" rx="2.5" stroke="#F6F6F6"/>
<path d="M7.91375 10.1671L4.78171 7.03503C4.56106 6.81439 4.2618 6.69043 3.94975 6.69043C3.63771 6.69043 3.33845 6.81439 3.1178 7.03503C2.89716 7.25568 2.7732 7.55494 2.7732 7.86698C2.7732 8.17903 2.89716 8.47829 3.1178 8.69893L4.24241 9.82549C5.34832 11.0012 5.36529 11.0012 4.24241 12.1745L3.11487 13.3011C2.89422 13.5217 2.77026 13.821 2.77026 14.133C2.77026 14.4451 2.89422 14.7443 3.11487 14.965C3.33552 15.1856 3.63478 15.3096 3.94682 15.3096C4.25886 15.3096 4.55812 15.1856 4.77877 14.965L7.91082 11.8329C8.02038 11.7239 8.10733 11.5943 8.1667 11.4515C8.22606 11.3088 8.25667 11.1558 8.25676 11.0012C8.25685 10.8466 8.22642 10.6935 8.16723 10.5507C8.10803 10.4079 8.02123 10.2782 7.9118 10.169L7.91375 10.1671Z" fill="#F6F6F6"/>
<path d="M13.4003 10.1671L10.2683 7.03503C10.0476 6.81439 9.74837 6.69043 9.43633 6.69043C9.12429 6.69043 8.82502 6.81439 8.60438 7.03503C8.38373 7.25568 8.25977 7.55494 8.25977 7.86698C8.25977 8.17903 8.38373 8.47829 8.60438 8.69893L9.72898 9.82549C10.8349 11.0012 10.8519 11.0012 9.72898 12.1745L8.60144 13.3011C8.38079 13.5217 8.25684 13.821 8.25684 14.133C8.25684 14.4451 8.38079 14.7443 8.60144 14.965C8.82209 15.1856 9.12135 15.3096 9.43339 15.3096C9.74543 15.3096 10.0447 15.1856 10.2653 14.965L13.3974 11.8329C13.5069 11.7239 13.5939 11.5943 13.6533 11.4515C13.7126 11.3088 13.7432 11.1558 13.7433 11.0012C13.7434 10.8466 13.713 10.6935 13.6538 10.5507C13.5946 10.4079 13.5078 10.2782 13.3984 10.169L13.4003 10.1671Z" fill="#F6F6F6"/>
<path d="M18.8867 10.1671L15.7546 7.03503C15.534 6.81439 15.2347 6.69043 14.9227 6.69043C14.6106 6.69043 14.3114 6.81439 14.0907 7.03503C13.8701 7.25568 13.7461 7.55494 13.7461 7.86698C13.7461 8.17903 13.8701 8.47829 14.0907 8.69893L15.2153 9.82549C16.3212 11.0012 16.3382 11.0012 15.2153 12.1745L14.0878 13.3011C13.8671 13.5217 13.7432 13.821 13.7432 14.133C13.7432 14.4451 13.8671 14.7443 14.0878 14.965C14.3084 15.1856 14.6077 15.3096 14.9197 15.3096C15.2318 15.3096 15.531 15.1856 15.7517 14.965L18.8837 11.8329C18.9933 11.7239 19.0802 11.5943 19.1396 11.4515C19.199 11.3088 19.2296 11.1558 19.2297 11.0012C19.2298 10.8466 19.1993 10.6935 19.1401 10.5507C19.0809 10.4079 18.9941 10.2782 18.8847 10.169L18.8867 10.1671Z" fill="#F6F6F6"/>
</svg>
`

export default str;
