import React, {useState} from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import {collatorSort} from '../../util';

import css from './styles.module.scss';

function CustomTable(props) {
  const {
    columns,
    rows,
    onRowClick = () => {},
    defaultSortCol,
    dark,
    bodyStyle,
    tableStyle,
    rowHeight
  } = props;

  const [sortCol, setSortCol] = useState(defaultSortCol);
  const [sortAsc, setSortAsc] = useState(true);

  const onHeaderClick = (e) => {
    if (e.dataKey === sortCol) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
    }
    setSortCol(e.dataKey);
  }

  const headerRow = columns.map((col) => {
    const colProps = {};

    if (col.componentFn) {
      colProps.cellRenderer = ({ rowData }) => {
        return col.componentFn(rowData);
      };
    } else if (col.valFn) {
      colProps.cellRenderer = ({ rowData }) => {
        return col.valFn(rowData);
      };
    }

    return (
      <Column
        key={col.name}
        label={col.label}
        dataKey={col.name}
        width={col.width || 100}
        flexGrow={col.flexGrow || 1}
        {...colProps}
      />
    );
  });

  const colObj = sortCol ? columns.find(c => c.name === sortCol) : null;

  const sortedRows = rows.slice().sort(
    collatorSort((row) => {
      if (!colObj) {
       return 1;
      }

      return colObj.valFn
         ? colObj.valFn(row)
         : row[colObj.name];
    }, sortAsc)
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={20}
          rowHeight={rowHeight || 40}
          rowCount={sortedRows.length}
          rowGetter={({index}) => sortedRows[index]}
          onRowClick={({ event, rowData }) => onRowClick(event, rowData)}
          onHeaderClick={onHeaderClick}
          sortBy={sortCol}
          sortDirection={sortAsc ? 'ASC' : "DESC"}
          className={`${css.table}, ${css.customTable}`}
          rowClassName={({ index }) => {
            if (index !== -1 && index % 2 === 0) {
              return `parent clickableRow even`
            } else if (index !== -1 && index % 2 === 1) {
              return `parent clickableRow odd`
            }
          }}
        >
          {headerRow}
        </Table>
      )}
    </AutoSizer>
  );
}

export default CustomTable;
