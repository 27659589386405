import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

import APIClient from '../apis/Client';

import AuthContext from '../context/AuthContext';

function clearAccessToken() {
  const cookies = new Cookies();
  cookies.remove('herdsense_token', { path: '/' });
}

const AuthLayout = ({ children }) => {
  const isRefreshingToken = useRef();

  const [searchParams] = useSearchParams();

  const queryRefreshToken = searchParams.get('token');
  const queryAccountId = searchParams.get('accountId');
  const queryCompanyId = searchParams.get('companyId');

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const accountId = parsedUser?.id;
  const companyId = parsedUser?.company_id;

  const logout = useCallback(async () => {
    try {
      clearAccessToken();
      localStorage.removeItem('herdsense_token');
      localStorage.removeItem('user');
      setUserData(null);
    } catch (error) {
      console.error('Logout failed to remove user:', error);
    }
  }, []);

  const saveUserData = useCallback((res) => {
    const { userData: user, refreshToken } = res;

    setUserData(user);
    setIsLoading(false);
    setUserData((state) => ({ ...user }));

    if (user) {
      window.localStorage.setItem("user", JSON.stringify(user));
    }

    if (refreshToken) {
      window.localStorage.setItem("herdsense_token", refreshToken);
    }
  }, []);

  const refresh = useCallback(async (accountId, companyId, token) => {
    if (!token) {
      return;
    }

    try {
      const res = await APIClient.post('/api/v1/auth/refresh', { accountId, companyId }, token);
      saveUserData(res);
    } catch (err) {
      // invalid refresh
      if (err.status === 401) {
        logout();
      } else {
        console.error(err);
      }
    }
  }, [logout, saveUserData]);

  async function runProtected(cb, ...args) {
    if (isRefreshingToken.current) {
      await new Promise((resolve) => {
        setInterval(() => {
          if (!isRefreshingToken.current) {
            resolve();
          }
        }, 100);
      });

      return cb(...args);
    }

    // refresh token if expired, then run callback
    isRefreshingToken.current = true;
    if (userData.exp < Date.now() / 1000) {
      const token = window.localStorage.getItem('herdsense_token');
      await refresh(accountId, companyId, token);
    }
    isRefreshingToken.current = false;

    return cb(...args);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let data = window.localStorage.getItem("user");
    if (!queryRefreshToken) {
      if (!data) {
        setUserData(null);
        setIsLoading(false);
        return;
      }
    }

    const token = window.localStorage.getItem('herdsense_token') || queryRefreshToken;
    if (!token || Date.now() / 1000 >= jwtDecode(token).exp) {
      setUserData(null);
      setIsLoading(false);
      return;
    }

    const loadData = async () => {
      data = JSON.parse(data);

      await refresh(
        data?.id || queryAccountId,
        data?.company_id || queryCompanyId,
        token || queryRefreshToken
      );

      setUserData(data);
      setIsLoading(false);
    };

    loadData();
  }, [refresh, isLoading, queryRefreshToken, queryAccountId, queryCompanyId]);

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      localStorage.setItem('herdsense_token', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
    } catch (error) {
      console.error('Login failed to parse JSON:', error);
    }
  };

  function selectCompany(user) {
    localStorage.setItem('user', JSON.stringify(user));
    setUserData(user);
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData: saveUserData,
        login,
        logout,
        selectCompany,
        runProtected,
        refresh
      }}
    >
      <div className="App">
        <div className="non-footer">
          <div id="PageContainer">
            {children}
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
};

export default AuthLayout;
