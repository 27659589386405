import React from 'react';
import { Modal } from 'react-bootstrap';

import Loader from '../Loader/Loader';

const AlertModal = (props) => {
  const {
    title = 'Cancel App',
    message,
    isLoading,
    btn1 = 'Cancel',
    btn2 = 'Yes',
    onAccept,
    onCancel,
    info = false,
    show = false
  } = props;

  if (!show) {
    return null;
  }

  const btnColor = info ? 'btn-primary' : 'btn-primary';

  const btn1El = btn1
    ? (
      <button
        type="button"
        className={`btn btn-outline-danger me-3`}
        data-dismiss="modal"
        onClick={() => onCancel(false)}
        disabled={isLoading}
      >{btn1}</button>
    )
    : null;

  const btn2El = btn2
    ? (
      <button
        type="button"
        className={`btn ${btnColor}`}
        style={{ marginLeft: 'auto' }}
        data-dismiss="modal"
        onClick={() => onAccept()}
        disabled={isLoading}
      >{btn2}</button>
    )
    : null;

  const modalBody = isLoading
    ? (
      <Loader />
    )
    : (
      <h3 className={`text-center`}>{message}</h3>
    );

  return (
    <Modal
      backdrop="static"
      size="l"
      centered
      show={show}
      onHide={() => onCancel(false)}
      scrollable
    >
      <Modal.Header closeButton>
        <h3 className='modal-title'>{title}</h3>
      </Modal.Header>

      <Modal.Body>
        {modalBody}
      </Modal.Body>

      <Modal.Footer>
        {btn1El}
        {btn2El}
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;
