import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { getAbsDay } from '../../util';

import css from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            stacked: false,
            ticks: {
                beginAtZero: true,
                stepSize: 1
            }
        },
    }
};

const commonGraphProps = {
  barPercetage: 0.5,
  barThickness: 12,
  maxBarThickness: 12,
  minBarLength: 2,
};

function CattleGraph(props) {
  const {
    data,
    startDate = new Date(),
    header,
    graphLabel,
    timeLabel,
    minDaysShown = 14,
    valueKey,
    multiple = false,
    barColor = 'rgb(48 153 253 / 50%)',
    graphHeight = 60
  } = props;

  const today = getAbsDay(new Date());
  const start = getAbsDay(startDate);

  const numberOfDays = Math.max(today - start, minDaysShown);

  const labels = new Array(numberOfDays).fill('').map((str, i) => {
    const time = (today - i) * 1000 * 60 * 60 * 24;
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const timestamp = new Date(time + offset);
    return `${timestamp.getMonth() + 1}/${timestamp.getDate()}`;
  }).reverse();

  const graphData = {
    labels,
    datasets: []
  }
  if (multiple) {
    graphData.datasets = data?.map((set, setIndex) => {
      const perDayData = set.reduce((arr, it) => {
        const itDay = getAbsDay(it.created_at);
        const index = today - itDay + 1;
        arr[index] = it[valueKey];
        return arr;
      }, new Array(numberOfDays).fill(0)).reverse();

      return {
        label: Array.isArray(graphLabel) ? graphLabel[setIndex] || graphLabel : graphLabel,
        backgroundColor: Array.isArray(barColor) ? barColor[setIndex] || barColor : barColor,
        ...commonGraphProps,
        data: perDayData
      }
    });
  } else {
    const perDayData = data?.reduce((arr, it) => {
      const itDay = getAbsDay(it.created_at);
      const index = today - itDay + 1;
      arr[index] = it[valueKey];
      return arr;
    }, new Array(numberOfDays).fill(0)).reverse();

    graphData.datasets = [
      {
        label: graphLabel,
        backgroundColor: barColor,
        ...commonGraphProps,
        data: perDayData
      },
    ]
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', color: '#383838', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>{header}</h3>
        <span style={{ fontSize: 12 }}>{timeLabel}</span>
      </div>
      <Bar data={graphData} options={options} height={graphHeight} />
    </div>
  );
}

export default CattleGraph;
